<!-- //朋友圈模板 -->
<template>
 <div>
    <div class="ntMain__head flexbox"><h2 class="hdtit flex1">朋友圈</h2>
     <span class="link" @click="isShowPublish=true"><i class="iconfont icon-tianjia fs-18"></i></span></div>
    <div class="ntMain__cont flex1 flexbox flex-col">
     <v3-scroll>
        <div class="nt__padCnt sm">
             <div class="nt__uZone">
            <div class="uzone__item">
            <div class="uzone__item-hdinfo flexbox">
                <img class="uzone__item-hdinfo-avator" src="@assets/img/uimg/img-avatar09.jpg" />
                <div class="flex1 flexbox flex-col">
                 <p class="uzone__item-hdinfo-name">一起2021</p><p class="uzone__item-hdinfo-subtxt">3分钟前</p>
                </div>
                <i class="iconfont icon-vdots" @click="showMenuLayer"></i>
            </div>
            <div class="uzone__item-cntbox">
             <div class="uzone__item-photo">
            <el-image hide-on-click-modal src="https://img.alicdn.com/bao/uploaded/i1/3503893379/O1CN01GE81aK1apcuND9nxW_!!3503893379.jpg_400x400.jpg" 
                 :preview-src-list="['https://img.alicdn.com/bao/uploaded/i1/3503893379/O1CN01GE81aK1apcuND9nxW_!!3503893379.jpg_400x400.jpg']"
                  />
             </div>
            <div class="uzone__item-cntxt">
             <h2>《2021》：乘风破浪</h2><p>世界很大，匆匆的脚步停留片刻，我们一起相约大理，遇见更好的自己！💘</p>
              </div>
            </div>
            <div class="uzone__item-foot flexbox"><p class="iconfont icon-dianzan fs-18 mr-15"></p><p class="iconfont icon-qianming fs-18"></p>
            </div>
             </div><div class="uzone__item">
            <div class="uzone__item-hdinfo flexbox"><img class="uzone__item-hdinfo-avator" src="@assets/img/uimg/img-avatar10.jpg" /><div class="flex1 flexbox flex-col">
            <p class="uzone__item-hdinfo-name">杜小丽</p><p class="uzone__item-hdinfo-subtxt">21分钟前</p>
             </div>
             <i class="iconfont icon-vdots" @click="showMenuLayer"></i>
            </div>
            <div class="uzone__item-cntbox">
             <div class="uzone__item-photo groups">
              <img src="https://img.alicdn.com/bao/uploaded/i4/2201483377267/O1CN01WPHbev23YKp6o4yQn_!!2201483377267.jpg_400x400.jpg" /><img src="https://img.alicdn.com/bao/uploaded/i1/2201483377267/O1CN01lvl9aT23YKkfmoT1X_!!2201483377267.jpg_400x400.jpg" />
               <img src="https://img.alicdn.com/bao/uploaded/i2/2201483377267/O1CN01XxbPf823YKqifRZnK_!!2201483377267.jpg_400x400.jpg" />
                </div>
            <div class="uzone__item-cntxt">
            <p>云南全景深度游+泸沽湖/香格里拉二选一+实名健康码安全游<br/>3大理旅拍+丽江风情+泸沽湖环游
            </p>
             </div>
            </div>
             <div class="uzone__item-foot flexbox"><p class="iconfont icon-dianzan fs-18 mr-15"></p><p class="iconfont icon-qianming fs-18"></p>
             </div></div>
         </div>
        </div>
         </v3-scroll>
        </div>
        <v3-layer v-model="isShowPublish" drawer="right" xclose z-index="1001" area="375px">
          <template #content>
         <div style="text-align:left;">
             <div class="flexbox pt-20 pb-10">
             <textarea class="flex1 ff-ar" name="content" placeholder="想记录点什么..." style="border:0;resize:none;height:60px;"></textarea>
               </div>
            <div class="vui-cell pt-10 pb-10">
              <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" multiple :limit="8">
            <i class="el-icon-plus"></i></el-upload>
              </div><div class="pt-10"><div class="vui-cell"><div class="flex1"><i class="iconfont icon-dingwei"></i><span class="fs-30 ml-20">你在哪里？</span></div><i class="iconfont icon-arrR c-999 fs-14"></i></div><div class="vui-cell"><div class="flex1"><i class="iconfont icon-mima"></i><span class="fs-30 ml-20">公开</span></div><i class="iconfont icon-arrR c-999 fs-14"></i></div>
              </div><div class="align-c mt-30"><div class="vui__btn vui__btn-primary"><i class="iconfont icon-send"></i> 发布</div>
         </div>
         </div>
         </template>
      </v3-layer>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
 components: {},
  setup() {
    const v3layer = inject('v3layer')
      const data = reactive({ isShowPublish: false
     })
      const showMenuLayer = () => {
         v3layer({type: 'androidSheet',
          btns: [{text: '分享'},{text: '转发该条动态'},{text: '屏蔽TA'},
         ]})
        }
      return {...toRefs(data), showMenuLayer
     }
  }
}
</script>
